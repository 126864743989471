import React from "react";
import { useState, useEffect } from "react";
import CryptoJS from "crypto-js";
import ScratchToReveal from "../ScratchToReveal";
import usImage from "../../assets/img/us.jpeg";
import { useLocation } from "react-router-dom";






const Elv = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/elvira") {
      document.body.style.overflow = "hidden"; // Lock scrolling
      document.body.style.transform = "scale(0.9)"; // Adjust the zoom level
      document.body.style.transformOrigin = "0 0"; // Anchor to top-left
      const meta = document.createElement("meta");
      meta.name = "viewport";
      meta.content = "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no";


    } else {
      document.body.style.overflow = ""; // Reset scrolling
      document.body.style.transform = ""; // Reset zoom
      document.body.style.transformOrigin = "";
    }
  }, [location]);

  const [password, setPassword] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const storedHash = "ebda25412007801d6829874271b92bfc";

  const onUpdate = (e) => {
    e.preventDefault();
    const inputHash = CryptoJS.MD5(password.toLowerCase()).toString();
    if (inputHash === storedHash) {
      setIsAuthenticated(true);
    } else {
      alert("Incorrect password");
    }
  };


  return (



    <div className="flex items-center justify-center min-h-screen">
      <div className="text-center">
        {!isAuthenticated && <>
          <h1 className="text-4xl font-bold mb-4">Hello &lt;3</h1>
          <form onSubmit={(e) => onUpdate(e)}>

            <input
              type="password"
              className="border p-2 rounded mb-4"
              placeholder="Enter password"
              onChange={(e) => setPassword(e.target.value)}
            />
            <button
              type="submit"
              className="bg-green-950 text-white p-2 rounded hover:bg-green-950"
            >
              Enter
            </button>
          </form>
        </>}
        {
          isAuthenticated &&
          <>
            <h1 className="text-4xl font-bold mb-4">Hiiiiiiiiiiiiii </h1>
            <h1 className="text-4xl font-bold mb-4">Scratch to Reveal </h1>
            <ScratchToReveal
              width={300}
              height={500}
              minScratchPercentage={50}
              gradientColors={["#A97CF8", "#F38CB8", "#FDCC92"]}
            >
              <img src={usImage} />
              <h1 className="text-4xl font-bold mb-4">I love you so much!!!</h1>

            </ScratchToReveal>
          </>
        }
      </div>
    </div>
  );
};

export default Elv;
